<template>
	<div class="app-container">
		<div class="col-12">
			<div class="cloud-area pt-5">
				<img class="cloud" src="@/assets/img/nofound/404.png" alt="404">
				<img class="cloud-child child-left" src="@/assets/img/nofound/404_cloud.png" alt="404">
				<img class="cloud-child child-mid" src="@/assets/img/nofound/404_cloud.png" alt="404">
				<img class="cloud-child child-right" src="@/assets/img/nofound/404_cloud.png" alt="404">
			</div>
			
			<br/>
			
			<div class="text-center mt-2">
				<h4 class="m-0" style="color:#606266">{{$t('error.nofound')}}</h4>
				<!-- <el-divider></el-divider> -->
				<el-button type="primary" icon="el-icon-back" @click="returnBack">{{$t('button.back_home')}}</el-button>
			</div>
		</div>
	</div>
</template>

<script>


export default{
	inject:['preloader'],
	methods:{
		returnBack(){
			this.$router.push('/dashboard');
		}
	},
	created:function(){
		this.preloader(false);
	}

}
</script>